@import "../../../styles/common.scss";

.workspaceInfo {
  margin: 2rem 0;
  display: flex;
  align-items: center;

  .details {
    margin-left: 1rem;
    flex: 1;

    h2 {
      margin: 0;
    }
  }
}