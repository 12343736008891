@import "../../styles/common.scss";

.workspaceColumn {
  display: flex;
  align-items: center;

  .workspaceInfo {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;

    strong {
      color: var(--primary);
      font-weight: bold;
    }

    small {
      color: var(--text-2);
    }
  }
}