@import "../../styles/common.scss";

.workspace {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;

  small {
    margin-left: 0.5rem;
    opacity: 0.8;
  }
}