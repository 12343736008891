.runOutput {
  pre {
    padding: 0;
    font-family: monospace;
    font-size: 1rem;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

.modal {
  [class*="ant-modal-content"] {
    padding: 0 !important;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  [class*="ant-modal-body"] {
    padding: 2rem;
    background-color: var(--blue);
    overflow: auto;
  }

  [class*="ant-modal-close"] {
    color: var(--white) !important;
  }
}