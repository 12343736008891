@import "../../../styles/common.scss";

.users {
  padding: 1rem;

  h2 {
    margin-bottom: 0;
  }
}

.deleteMany {
  width: 14rem;
  font-weight: bold;
  right: 0 !important;
  left: 0 !important;
  margin: auto;
  box-shadow: 0 0 10rem var(--blue);

  [class="ant-float-btn-body"] {
    background-color: var(--pink);
    transition: all 0.2s;
  }

  [class="ant-float-btn-icon"] {
    width: 100% !important;
  }

  &:hover {
    [class="ant-float-btn-body"] {
      background-color: var(--pink);
      filter: brightness(1.2);
    }
  }
}