@import "../../styles/common.scss";

.create {
  max-width: 1440px;
  margin: 0 auto;

  label {
    margin-bottom: 0.5rem;
    display: block;
  }

  .users {
    width: 100%;
    margin-bottom: 1rem;
  }

  .user {
    margin-bottom: 0.2rem;
  }

  [class*="ant-input"] {
    margin-bottom: 1rem;
  }

  [class*="ant-tooltip"] {
    display: none;
  }

  .alert {
    color: #212121;
    margin-bottom: 1rem;
  }
}

.modal {
  [class="ant-modal-title"] {
    color: #212121 !important;
  }

  [class*="ant-btn"] span {
    color: var(--blue) !important;
  }

  [class*="ant-btn"][class*="ant-btn-primary"] span {
    color: white !important;
  }
}