.accountSwap {
  padding: 1rem;
  margin: 1rem -0.5rem 0;
  display: flex;
  align-items: center;

  border: 1px solid var(--border);
  border-radius: 0.5rem;

  @media screen and (max-width:1200px) {
    border-color: #ddd;
  }

  .workspaceLogo {
    width: 2rem;
  }

  .workspaceInfo {
    padding: 0 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @media screen and (min-width:1200px) {
      strong {
        font-weight: bold;
        color: var(--text);
      }
    }

    span {
      font-weight: bold;
      font-size: 0.7rem;
      color: var(--text-2);
    }
  }

  .swapIcon {
    span {
      color: var(--pink);
      display: block;
      width: 18px;
      height: 18px;
    }
  }

  &.compact {
    padding: 0;
    margin: 1rem 0;
    border: none;

    .workspaceInfo, .swapIcon {
      display: none;
    }
  }
}