.selectPartner {
  padding: 3rem;
  max-width: 800px;
  margin: 0 auto;

  .partner {
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    background-color: var(--body-2);
    border-radius: 0.5rem;

    .info {
      margin-left: 1rem;
      color: var(--text);
    }
  }
}